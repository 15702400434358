<template>
  <div class="container">
    <div class="content">
      <breadcrumb :items="currentLocation" />
      <div class="filter-box">
        <p>筛选条件:</p>
        <div class="tags">
          <div class="tag" v-for="(tag, idx) in filterTags" :key="tag.id">
            <span>{{ tag.title }}：</span>
            <span class="name">{{ tag.name }}</span>
            <i class="el-icon-close" @click="tagClick(idx)" />
          </div>
        </div>
      </div>
      <subject-classify
        :group="subjectGroup"
        :filter="searchFilter.classify"
        :key="refKey"
        :isSubject="isSubject"
        @subject-item="subjectItem"
        @filter-item="filterItem"
        v-loading="loading"
      />
      <div class="main" v-loading="loading">
        <left-menu
          :left="leftData"
          :key="leftKey"
          :filter="leftNewFilter"
          :defaultPublisher="defaultPublisher"
          :defaultSeries="defaultSeries"
          :delTag="delTag"
          :child="leftChild"
          @childByValue="getLeftFilter"
          @filter-left="filterLeft"
        />
        <div class="list-container">
          <div class="list-top">
            <span>
              共
              <span class="total-count">{{ page.total }}</span>
              种记录
            </span>
            <div class="top-actions">
              <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                @change="selectAll"
              >
                本页全选
              </el-checkbox>
              <el-button type="primary" v-if="canAdd" @click="batchAdd">
                批量收藏到书单
              </el-button>
            </div>
          </div>
          <div class="sort-search">
            <sort-buttons
              :sortFilters="orderActions"
              :sortKey="sortKey"
              @change-sort="changeSort"
            />
            <search-input
              :refreshKey="searchKey"
              :options="searchOptions"
              @do-search="doSearch"
            />
          </div>
          <no-content class="no-content" v-if="emptyList" />
          <div class="item-list" v-else>
            <div class="item-box" v-for="(item, index) in bookList" :key="item.ruid">
              <div class="left-box">
                <p>{{ index + 1 + page.pageno * page.pagesize }}</p>
                <el-checkbox v-model="selected" :label="item.id" @change="changeSelect" />
              </div>
              <div class="detail">
                <BookListItem
                  :book="item"
                  :rows="detailRows"
                  @button-click="buttonClick"
                  @defaultPublisher="publisherClick"
                  @defaultSeries="seriesClick"
                />
              </div>
            </div>
            <list-pager :page="page" @change-page="changePage" />
          </div>
        </div>
      </div>
    </div>
    <add-to-list
      :showDialog="showDialog"
      :items="addItems"
      @close-export="showDialog = false"
      @save-list="saveList"
    />
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showReason"
      width="800px"
      title="请输入评论内容"
      @close="resetForm('newForm')"
    >
      <el-form
        :model="newForm"
        :rules="rules"
        ref="newForm"
        class="reason-form"
        label-width="130px"
      >
        <el-form-item label="评论内容：" prop="reason">
          <el-input
            type="textarea"
            class="input"
            v-model="newForm.reason"
            maxlength="2000"
            placeholder="请输入评论内容，不得少于50字"
            :rows="5"
          />
        </el-form-item>
        <el-form-item>
          <div class="buttons">
            <el-button type="info" @click="resetForm('newForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('newForm')">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SubjectClassify from 'components/SubjectClassify'
import Breadcrumb from 'components/Breadcrumb'
import LeftMenu from 'components/LeftMenu'
import SortButtons from 'components/SortButtons'
import SearchInput from 'components/SearchInput'
import ListPager from 'components/ListPager'
import NoContent from 'components/NoContent'
import BookListItem from './BookListItem'
import AddToList from './AddToList'

import rows from 'assets/js/detailRows'

export default {
  components: {
    SubjectClassify,
    Breadcrumb,
    LeftMenu,
    SortButtons,
    SearchInput,
    BookListItem,
    ListPager,
    NoContent,
    AddToList,
  },
  name: 'BookList',
  data() {
    let validateReason = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入评论内容'))
      } else if (value.length < 50) {
        return callback(new Error('评论内容不能少于50字'))
      } else {
        callback()
      }
    }
    return {
      currentLocation: [
        {
          name: '资源首页',
          path: '/home',
        },
        {
          name: '图书',
          path: '',
        },
      ],
      subjectGroup: null,
      page: {
        pageno: 0,
        pagesize: 10,
        total: 0,
      },
      order: {
        orderfield: '',
        ordertype: '',
      },
      loading: false,
      bookList: [],
      newleft: [],
      leftData: [],
      classify: [],
      leftFilter: [],
      filter: [],
      orderActions: [],
      searchOptions: [],
      delTag: null,
      detailRows: rows,
      checkAll: false,
      isIndeterminate: false,
      selected: [],
      subject: '',
      leftKey: 0,
      refKey: 0,
      searchKey: 0,
      fromLeft: false,
      searching: false,
      showDialog: false,
      filterTags: [],
      addItems: [],
      searchFilter: {},
      leftNewFilter: [],
      topFilter: [],
      publishFilter: [],
      leftChild: null,
      loaded: false,
      defaultPublisher: '',
      defaultSeries: '',
      showReason: false,
      reason: '',
      starItem: null,
      sortKey: 0,
      newForm: {},
      rules: {
        reason: [{ required: true, validator: validateReason, trigger: 'blur' }],
      },
      isSubject: true,
      locationSet: false,
      firstLoad: true,
    }
  },
  computed: {
    emptyList() {
      const list = this.bookList
      return list.length < 1 ? true : false
    },
    canAdd() {
      const token = localStorage.getItem('token')
      if (token) return true
      return false
    },
  },
  watch: {
    loaded(val) {
      if (!val) return false
      const classify = this.searchFilter.classify.length
      const left = this.searchFilter.left.length
      if (!classify && !left) {
        this.getListData()
      }
    },
  },
  methods: {
    publisherClick(name) {
      let filter = {
        logicalop: 'and',
        relationop: 'in',
        title: '出版社',
        fieldname: 10400001,
        name: name,
        word: name,
        advanced: true,
      }
      let filters = this.filterTags
      let target = filters.find((item) => item.name === name)
      if (!target) {
        filters.push(filter)
      }
      this.searchFilter.top = filters
      this.getListData()
    },
    seriesClick(name) {
      let filter = {
        logicalop: 'and',
        relationop: 'in',
        title: '丛书',
        fieldname: 10140001,
        name: name,
        word: name,
        advanced: true,
      }
      let filters = this.filterTags
      let target = filters.find((item) => item.name === name)
      if (!target) {
        filters.push(filter)
      }
      this.searchFilter.top = filters
      this.getListData()
    },
    batchAdd() {
      if (!this.selected.length) return this.$message.error('请先选择书目')
      let items = []
      this.selected.forEach((item) => {
        let target = this.bookList.find((book) => item === book.id)
        if (target) items.push({ id: target.id, ruid: target.ruid })
      })
      this.addItems = items
      this.showDialog = true
    },
    saveList() {
      this.showDialog = false
      this.checkAll = false
      this.isIndeterminate = false
      this.selected = []
      this.getListData()
    },
    handleDefaultPublisher(name) {
      this.defaultPublisher = name
        .split("<font style='color:red'>")
        .join('')
        .split('</font>')
        .join('')
      this.loading = true
      this.leftData = []
      if (this.defaultPublisher) {
        let flag = false
        this.newleft.forEach((element) => {
          if (element.name == '出版社') {
            element.items.forEach((item) => {
              if (item.name == this.defaultPublisher) {
                flag = true
              }
            })
          }
        })
        if (!flag) {
          this.newleft[10].items.unshift({
            filterfield: '10400001',
            filterflag: '1',
            name: this.defaultPublisher,
            result: 10,
            ruid: this.defaultPublisher,
          })
        }
      }
      setTimeout(() => {
        this.leftData = this.newleft
        this.loading = false
      }, 500)
    },
    handleDefaultSeries(name) {
      this.defaultSeries = name
      this.loading = true
      this.leftData = []
      if (this.defaultSeries) {
        let flag = false
        this.newleft.forEach((element) => {
          if (element.name == '丛书') {
            element.items.forEach((item) => {
              if (item.name == this.defaultSeries) {
                flag = true
              }
            })
          }
        })
        if (!flag) {
          this.newleft[11].items.unshift({
            filterfield: '10140001',
            filterflag: '1',
            name: this.defaultSeries,
            result: 10,
            ruid: this.defaultSeries,
          })
        }
      }
      setTimeout(() => {
        this.leftData = this.newleft
        this.loading = false
      }, 500)
    },
    buttonClick(button) {
      let item = this.bookList[button.index]
      switch (button.text) {
        case '收藏到书单':
          this.addItems = [{ id: item.id, ruid: item.ruid }]
          this.showDialog = true
          break
        case '评论':
          this.starItem = item
          this.showReason = true
          break
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkWord()
        } else {
          return false
        }
      })
    },
    checkWord() {
      const body = { reason: this.newForm.reason }
      this.$http({
        url: `/api/v1/platform/libinfoBadword/review`,
        method: 'POST',
        data: body,
      }).then((res) => {
        if (res.cxajaxrc == 0) {
          this.newForm.reason = res.returnvalue.reason
          this.starBook()
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.newForm = {}
      this.ruleForm = {}
      this.showReason = false
    },
    async starBook() {
      let userId = localStorage.getItem('userRuid')
      const body = {
        userId,
        dataId: [this.starItem.id],
        type: 'USER',
        reason: this.newForm.reason,
      }
      const res = await this.$http.post('/api/v1/user/personaldata/addStar', body)
      if (res.cxajaxrc != 0) return false

      this.$message.success('评论成功')
      this.showReason = false
      this.getListData()
    },
    tagClick(index) {
      let tags = this.filterTags
      let tag = tags[index]
      if (tag.title == '自定义条件') {
        tags.splice(index, 1)
        this.filterTags = tags
        let filters = this.searchFilter.top
        filters.splice(index, 1)
        this.searchFilter.top = filters
        this.getListData()
      } else if (tag.advanced) {
        tags.splice(index, 1)
        this.filterTags = tags
        let filters = this.searchFilter.top.filter((item) => {
          return item.title != tag.title
        })
        this.searchFilter.top = filters
        this.getListData()
      } else {
        this.delTag = tags[index]
        tags.splice(index, 1)
      }
    },
    changeSort(obj) {
      this.order = obj
      this.getListData()
    },
    doSearch(obj) {
      if (obj.value.substring(0, 3) === '978') {
        obj.value = this.lodash.replace(obj.value, /-/g, '')
      }
      this.page.pageno = 0
      let filter = {
        logicalop: 'and',
        relationop: 'like',
        fieldname: obj.option,
        word: obj.value,
      }
      this.searching = true
      this.filter = filter
      this.getListData()
    },
    resetPage() {
      this.searchFilter.search = []
      this.subject = ''
      this.classify = []
      this.leftFilter = []
      this.filter = []
      this.filterTags = this.filterTags.filter((tag) => tag.advanced)
      this.delTag = null
      this.page.pageno = 0
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false
    },
    filterItem(item) {
      this.subject = item.urlappend
      const filter = item.filterfield[0]
      this.classify = [filter]

      const left = this.searchFilter.left
      if (left.length) {
        this.leftNewFilter = this.searchFilter.left
      } else {
        this.getListData()
      }
    },
    subjectItem(item) {
      this.searchKey++
      this.resetPage()
      if (item.urlappend) {
        this.subject = item.urlappend
        const filter = item.filterfield[0]
        this.classify = [filter]
      } else {
        this.classify = []
      }
      this.filterTags = this.filterTags.filter((tag) => tag.advanced)
      this.leftFilter = []
      this.leftKey++
      this.getListData()
    },
    selectAll(val) {
      this.checkAll = val
      const list = this.bookList
      if (val) this.selected = list.map((item) => item.id)
      else this.selected = []
      this.isIndeterminate = false
    },
    changeSelect() {
      const selected = this.selected.length
      const list = this.bookList.length
      if (selected === list) {
        this.isIndeterminate = false
        this.checkAll = true
      } else {
        this.checkAll = false
        if (!selected) this.isIndeterminate = false
        else this.isIndeterminate = true
      }
    },
    filterLeft(filter) {
      this.getLeftFilter(filter)
    },
    getLeftFilter(filter) {
      this.page.pageno = 0
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false

      let array = []
      let existTags = []
      let newTags = []
      filter.forEach((item) => {
        if (item.filterflag == 1) {
          let postbody = {
            logicalop: 'and',
            fieldname: item.filterfield,
            relationop: 'raw',
            word: item.ruid,
          }
          array.push(postbody)
        } else if (item.filterflag == 100) {
          item.filterfield.forEach((item) => {
            array.push(item)
          })
        } else {
          let filterTags = this.filterTags
          let target = filterTags.find((tag) => tag.name === item.name)
          if (!target) {
            newTags.push(item)
          } else {
            existTags.push(item)
          }
        }
      })

      let publishTags = this.filterTags.filter((tag) => tag.advanced)
      let finalTags = newTags.concat(existTags)
      this.filterTags = publishTags.concat(finalTags)
      // if (newTags.length) {
      //   this.filterTags = publishTags.concat(newTags)
      // } else {
      //   this.filterTags = publishTags.concat(existTags)
      // }

      this.filter = []
      this.searchKey++

      this.leftFilter = array
      this.fromLeft = true
      this.getListData()
      this.defaultPublisher = ''
      this.defaultSeries = ''
    },
    changePage(page) {
      this.selected = []
      this.checkAll = false
      this.isIndeterminate = false
      this.page.pageno = page

      this.getListData()
    },
    getFilter() {
      // 合并过滤条件
      let searchFilter = this.searchFilter.search || []
      let topFilter = this.searchFilter.top || []
      let extraFilter = searchFilter.concat(this.publishFilter)
      extraFilter = extraFilter.concat(topFilter)
      let filter = this.classify.concat(this.leftFilter)
      // filter = filter.concat(this.filter)
      let bodyData = {
        classify: filter.concat(topFilter),
        leftFilter: filter.concat(extraFilter),
        filter: filter.concat(extraFilter).concat(this.filter),
      }

      if (!Object.keys(this.searchFilter).length) {
        // 判断是何处来的条件
        if (this.fromLeft) {
          delete bodyData.classify
        } else if (this.searching) {
          delete bodyData.classify
          delete bodyData.leftFilter
        }
      }

      this.fromLeft = false
      this.searching = false

      return bodyData
    },
    async getListData() {
      this.loading = true

      // 拼接参数
      let obj = Object.assign(this.page, this.order)
      let param = this.getParams(obj)
      param += this.subject
      const bodyData = this.getFilter()

      // 发送请求
      const res = await this.$http.post('/api/v1/datasearch/book_all' + param, bodyData)
      if (res.cxajaxrc != 0) return false

      const val = res.returnvalue
      if (val) {
        this.page.total = val.recordcount

        if (!this.searchOptions || !this.searchOptions.length) {
          this.searchOptions = val.searchactions[0].options
        } else {
          if (
            this.searchOptions[0].value ==
            '10100001,10110001,10120001,10130001,15900001,10400001,281450001'
          ) {
            this.searchOptions[0].value =
              '10100001,10110001,10120001,10130001,15900011,10400001,281450001'
          }
        }

        if (!val.recordlist) this.bookList = []
        else
          this.bookList = val.recordlist.map((item, index) => {
            if (item.relatdbooks) {
              val.recordlist[index].glList = item.relatdbooks.relatedResult.recordlist
              val.recordlist[index].bindingList = []
              item.relatdbooks.relatedResult.recordlist.forEach((item) => {
                val.recordlist[index].bindingList.push(item.binding)
              })
              val.recordlist[index].bindingList = [
                ...new Set(val.recordlist[index].bindingList),
              ]
            }

            item.index = index
            const buttons = item.recordaction
            if (buttons) {
              item.recordaction = buttons.map((item) => {
                // btn.type = (btn.text.includes('评论') ? 'primary' : 'plain')
                // return btn
                // debugger
                if (item) {
                  if (item.ruid == 'add_star_data') {
                    item.type = 'primary'
                  } else if (item.ruid == 'add_booklist_data') {
                    item.type = 'plain'
                  } else if (item.ruid == 'add_star_data_done') {
                    item.type = 'info'
                  } else if (item.ruid == 'add_booklist_data_done') {
                    item.type = 'info'
                  }
                  return item
                }
              })
            }
            return item
          })
        if (this.subjectGroup == null) {
          this.subjectGroup = val.classifygroup
        } else {
          let cache = JSON.parse(JSON.stringify(this.subjectGroup))
          cache.forEach((item1) => {
            item1.items = []
            val.classifygroup.forEach((item2) => {
              if (item1.root == item2.root) {
                item1.items = item2.items
              }
            })
          })
          this.subjectGroup = cache
        }

        this.orderActions = val.orderactions

        this.leftData = val.left.map((item) => {
          item.newArrays = []
          return item
        })
        this.newleft = this.leftData
      }

      if (this.defaultSeries) {
        let flag = false
        this.newleft.forEach((element) => {
          if (element.name == '丛书') {
            element.items.forEach((item) => {
              if (item.name == this.defaultSeries) {
                flag = true
              }
            })
          }
        })
        if (!flag) {
          this.newleft[11].items.unshift({
            filterfield: '10140001',
            filterflag: '1',
            name: this.defaultSeries,
            result: 10,
            ruid: this.defaultSeries,
          })
        }
      }
      if (sessionStorage.getItem('newest')) {
        this.sortKey++
        sessionStorage.removeItem('newest')
      }
      this.loading = false
      if (!this.firstLoad || this.filterTags.length) {
        document.body.scrollTop = document.documentElement.scrollTop = 460
      }
      this.firstLoad = false
    },
    checkFilter() {
      const searchFilter = sessionStorage.getItem('searchFilter')
      if (!searchFilter) return this.getListData()
      const filter = JSON.parse(searchFilter) || {}

      let filterArr = filter.search.concat(filter.left)
      filterArr = filterArr.concat(filter.top)
      filterArr.forEach((item) => {
        if (item.fieldname == 331450001) {
          this.isSubject = false
        } else if (item.children && item.children.length) {
          item.children.forEach((child) => {
            if (child.fieldname == 331450001) {
              this.isSubject = false
            }
          })
        }
      })
      this.searchFilter.top = filterArr

      const tags = this.getTags(filter)
      this.filterTags = tags

      sessionStorage.removeItem('searchFilter')
      this.getListData()
    },
    checkLocation(item) {
      if (this.locationSet) return

      if (item.home) {
        if (!item.name) return
        this.currentLocation = [
          {
            name: '资源首页',
            path: '/home',
          },
          {
            name: item.name,
            path: '',
          },
        ]
        this.locationSet = true
      } else if (item.advanced) {
        this.currentLocation = [
          {
            name: '资源首页',
            path: '/home',
          },
          {
            name: '高级检索',
            path: '/search',
          },
          {
            name: '高级检索结果页',
            path: '',
          },
        ]
        this.locationSet = true
      }
    },
    getTags(filter) {
      let tags = []
      filter.left.forEach((item) => {
        this.checkLocation(item)
        if (item.title === '出版时间') {
          // 如果已经存在出版时间标签则跳出循环
          if (tags.find((item) => item.fieldname == 10500011)) return
          let start = filter.left.find((item) => item.relationop === '>=')
          let end = filter.left.find((item) => item.relationop === '<=')
          let dateTag = {
            title: '出版时间',
            fieldname: 10500011,
            name: `${start.word} - ${end.word}`,
            advanced: true,
          }
          tags.push(dateTag)
          return
        }
        let tag = {
          title: item.title,
          name: item.name || item.word,
          fieldname: item.fieldname,
          advanced: true,
        }
        let dupIndex = tags.findIndex((elem) => elem.title === item.title)
        if (dupIndex != -1) {
          if (item.name) tags[dupIndex].name += `,${item.name || item.word}`
        } else {
          tags.push(tag)
        }
      })

      let topTag
      filter.top.forEach((item) => {
        this.checkLocation(item)
        if (topTag) {
          if (item.name) topTag.name += `,${item.name}`
          else topTag.name += ''
        } else {
          topTag = {
            title: item.title,
            name: item.name,
            fieldname: item.fieldname,
            advanced: true,
          }
        }
      })
      if (topTag) tags.push(topTag)

      const searchObj = filter.search[0]
      if (searchObj && searchObj.children.length) {
        this.checkLocation(searchObj)
        tags.push(searchObj)
      }
      /* filter.search.forEach(item => {
        this.checkLocation(item)
        let tag = {
          title: item.title,
          name: item.word,
          fieldname: item.fieldname,
          advanced: true
        }
        let tag = item
        tags.push(tag)
      }) */

      return tags
    },
    checkSearch() {
      const filter = sessionStorage.getItem('searchFilter')
      if (!filter) return false
      this.searchFilter = JSON.parse(filter) || {}
      const top = this.searchFilter.top
      const left = this.searchFilter.left
      const search = this.searchFilter.search

      let topFilter = []
      if (search.length) {
        search.forEach((item) => {
          if (item.fieldname == 10400005) {
            this.getPublisher(item)
          } else if (item.fieldname == 10500021) {
            this.publishTime(item)
          } else {
            topFilter.push(item)
          }
        })
        this.loaded = true
      }
      this.searchFilter.search = topFilter
      // this.topFilter = topFilter

      if (!top.length && left.length) {
        this.leftNewFilter = this.searchFilter.left
      }

      sessionStorage.removeItem('searchFilter')
    },
    publishTime(item) {
      let time = item.time
      if (!time.start || !time.end) return false

      let filter = [
        {
          logicalop: 'and',
          fieldname: '10500021',
          relationop: 'greaterorequal',
          word: time.start,
          valuetype: 'L',
        },
        {
          logicalop: 'and',
          fieldname: '10500021',
          relationop: 'lessorequal',
          word: time.end,
          valuetype: 'L',
        },
      ]

      let publishFilter = this.publishFilter
      publishFilter = publishFilter.concat(filter)
      this.publishFilter = publishFilter
      let tag = {
        title: item.name,
        name: `${time.start}-${time.end}`,
        fieldname: item.fieldname,
        publish: true,
      }
      this.filterTags.push(tag)
    },
    getParams(obj) {
      let param = ''
      let idx = 0
      for (let key in obj) {
        if ((!obj[key] && obj[key] != 0) || key == 'total') continue
        let dot = idx === 0 ? '?' : '&'
        param += dot + (key + '=' + obj[key])
        idx += 1
      }
      return param + '&reviewApproved=true&wfstatusIsA=true'
    },
    async getPublisher(elem) {
      let publisher = elem.publisher
      let filter = {
        logicalop: 'and',
        fieldname: '10400001',
        relationop: 'raw',
        word: publisher.name,
      }
      this.publishFilter.push(filter)
      let tag = {
        title: '出版社',
        name: publisher.name,
        fieldname: 10400001,
        publish: true,
      }
      this.filterTags.push(tag)
    },
  },
  mounted() {
    // this.getListData()
    this.checkFilter()
    this.$bus.$on('refreshBookList', () => {
      this.refKey++
      this.leftKey++
      this.searchKey++
      this.resetPage()
      this.filterTags = []
      this.searchFilter.top = []
      let filter = sessionStorage.getItem('searchFilter')
      let searchFilter = JSON.parse(filter)
      this.searchFilter.top = searchFilter.top
      const obj = searchFilter.top[0]
      if (obj.word) {
        this.filterTags.push({
          title: '自定义条件',
          name: obj.word,
          fieldname: obj.fieldname,
          advanced: true,
        })
      }
      sessionStorage.removeItem('searchFilter')
      this.getListData()
    })
  },
  beforeDestroy() {
    this.$bus.$off('refreshBookList')
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #eeefef;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    padding-top: 10px;
    > * {
      margin-bottom: 10px;
    }
    .filter-box {
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 20px;
      p {
        margin-right: 10px;
        min-width: 75px;
      }
      .tags {
        .tag {
          border: 1px solid #ddd;
          display: inline-block;
          // height: 23px;
          line-height: 23px;
          padding: 0 5px;
          margin-right: 10px;
          .name {
            color: $mainColor;
          }
          i {
            cursor: pointer;
            margin-left: 3px;
          }
        }
      }
    }
    .main {
      display: flex;
      /deep/ .container_left {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
      }
      .list-container {
        padding: 20px;
        width: 100%;
        margin-left: 10px;
        background: #fff;
        > * {
          margin-bottom: 15px;
        }
        .list-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #eee;
          padding: 12.5px 15px;
          color: #666666;
          .total-count {
            color: #f00;
            margin: 0 5px;
          }
          .el-button {
            margin-left: 20px;
          }
        }
        .sort-search {
          display: flex;
          justify-content: space-between;
          //   /deep/ button{
          //     padding: 8.5px 20px !important;
          //   }
          //   /deep/ .el-input__inner{
          //     height: 35px;
          //   }
        }
        .no-content {
          height: 500px;
        }
        .item-list {
          .item-box {
            display: flex;
            padding: 20px 0;
            border-bottom: 1px solid #e6e6e6;
            &:nth-of-type(1) {
              padding-top: 5px;
            }
            .left-box {
              text-align: center;
              margin-right: 20px;
              p {
                font-size: 1.2rem;
                margin-bottom: 5px;
              }
              /deep/ .el-checkbox__label {
                display: none;
              }
            }
            .detail {
              flex: 1;
            }
          }
        }
      }
    }
  }
}
.reason-form {
  padding: 0 40px;
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
  /deep/ .el-form-item__label,
  /deep/ .el-textarea__inner {
    resize: none;
    font-size: 20px;
    border-radius: 0;
  }
  .buttons {
    display: block;
    margin-top: 20px;
    text-align: right;
    button {
      font-size: 20px;
      padding: 10px 25px;
    }
    .el-button--info {
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;
      color: #666;
    }
  }
}
</style>
