<template>
  <div>
    <div class="book-detail">
      <div class="left">
        <div class="cover" @click="toDetail(book.ruid)">
          <img :src="getImage" />
          <span class="type">{{ book.dmcode }}</span>
        </div>
        <!-- <div v-for="item in book.coveraction" :key="item.name">
            <p class="value">{{ item.value }}</p>
            <p class="name">{{ item.name }}</p>
          </div> -->
        <div class="count">
          <div>
            <p class="value">{{ book.starcount ? book.starcount : 0 }}</p>
            <p class="name">书评数</p>
          </div>
          <div>
            <p class="value">{{ book.favorcount ? book.favorcount : 0 }}</p>
            <p class="name">收藏数</p>
          </div>
          <div>
            <p class="value">{{ book.libcount ? book.libcount : 0 }}</p>
            <p class="name">馆藏数</p>
          </div>
        </div>
      </div>
      <div class="info">
        <h4 v-html="book.title" @click="toDetail(book.ruid)" />
        <p v-html="book.titlecn" />
        <div class="detail">
          <div class="row">
            <div v-if="book.authorinfo">
              <b>作者信息</b>
              <span v-html="book.authorinfo"></span>
            </div>
          </div>
          <div class="row">
            <div v-if="book.publisher">
              <b>出版社</b>
              <span v-html="book.publisher"></span>
              <span v-if="book.publisherbrandname">, {{ book.publisherbrandname }}</span>
            </div>
            <div v-if="book.publishyear">
              <b>出版时间</b>
              <span>{{ book.publishyear }}</span>
            </div>
          </div>
          <div class="row">
            <span>
              <b>ISBN13</b>
              <template v-if="book.relatdbooks.relatedResult.recordcount > 0">
                <label
                  @click="toDetail(element.ruid)"
                  class="isbn"
                  v-for="(element, idx) in book.glList"
                  :key="idx"
                >
                  <i v-html="element.isbn"></i>
                  {{ glIcon('glList', idx) }}
                </label>
              </template>
              <label v-else class="isbn" @click="toDetail(book.ruid)">
                <i v-html="book.isbn"></i>
              </label>
            </span>
          </div>
          <div class="row">
            <span>
              <b>ISBN10</b>
              <template v-if="book.relatdbooks.relatedResult.recordcount > 0">
                <label
                  @click="toDetail(element.ruid)"
                  class="isbn"
                  v-for="(element, idx) in book.glList"
                  :key="idx"
                >
                  <i v-html="element.isbn10"></i>
                  {{ glIcon('glList', idx) }}
                </label>
              </template>
              <label v-else class="isbn" @click="toDetail(book.ruid)">
                <i v-html="book.isbn10"></i>
              </label>
            </span>
          </div>
          <div class="row">
            <div v-if="book.seriestitle">
              <b>丛书</b>
              <span>{{ book.seriestitle }}</span>
              <span v-if="book.seriesno">,{{ book.seriesno }}</span>
            </div>
          </div>
          <div class="row">
            <span v-show="book.binding">
              <b>装帧</b>
              <template v-if="book.relatdbooks.relatedResult.recordcount > 0">
                <label v-for="(element, idx) in book.bindingList" :key="idx">
                  {{ element }}{{ glIcon('bindingList', idx) }}
                </label>
              </template>

              <label v-else v-html="book.binding"></label>
            </span>
          </div>
          <div class="row">
            <div v-if="book.edition">
              <b>版次</b>
              <span>{{ book.edition }}</span>
            </div>
            <div v-if="book.pagesInfo">
              <b>页码</b>
              <span>{{ book.pagesInfo }}</span>
            </div>
            <div v-if="book.language">
              <b>语种</b>
              <span>{{ book.language }}</span>
            </div>
          </div>
          <span class="version" v-if="book.refcount">
            有 {{ book.refcount }} 个版本可选
          </span>
        </div>
        <div class="tips">
          <span v-if="book.supplyState">{{ book.supplyState }}</span>
          <span v-if="getSaleCount != '没有现货'">{{ getSaleCount }}</span>
        </div>
        <div class="buttons button-review">
          <el-button
            v-show="btn.ruid != 'data_online_read_398370'"
            :plain="btn.ruid == 'add_booklist_data_done'"
            v-for="(btn, idx) in book.recordaction"
            :key="btn.ruid || btn.text"
            :type="btn.type"
            ref="detailButton"
            :disabled="disableButton(book)"
            @click="buttonClick(btn, idx)"
          >
            {{ btn.text }}
          </el-button>
          <RecommendButton :isbn="book.isbn" />
          <div v-if="book.reviewState" class="review-info">
            <span
              v-if="
                book.reviewState == 'manual_ok_warn' ||
                book.reviewState == 'auto_reject' ||
                book.reviewState == '6' ||
                book.reviewState == '21'
              "
            >
              *本书可能含有法律法规禁止的内容
            </span>
            <span v-else-if="book.reviewRejectInfo || book.reviewRejectReason">
              *{{ book.reviewRejectInfo || book.reviewRejectReason }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="record" v-show="book.relatdbooks.relatedResult.recordcount > 0">
      <el-table
        class="table"
        style="width: 100%"
        ref="multipleTable"
        :data="book.relatdbooks.relatedResult.recordlist"
        border
        :header-cell-style="{
          'text-align': 'center',
          background: '#F0F0F0',
          color: '#333',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="资源类型及载体" center>
          <template slot-scope="scope">
            <p>
              {{ getType(scope.row.type) }}
              <span v-if="scope.row.typeInfo">({{ scope.row.typeInfo }})</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column property="editionStr" label="版本版次装帧卷册">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.versionStr">{{ scope.row.versionStr }},</span>
              <span v-if="scope.row.editionStr">{{ scope.row.editionStr }},</span>
              <span v-if="scope.row.binding">{{ scope.row.binding }},</span>
              <span v-if="scope.row.volumeNo">{{ scope.row.volumeNo }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="ISBN" width="150">
          <template slot-scope="scope">
            <p class="isbn" @click="toDetail(scope.row.ruid)">
              <i>{{ scope.row.isbn }}</i>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="价格" width="120">
          <template slot-scope="scope">
            <p class="redColor">{{ scope.row.priceType }}{{ scope.row.price }}</p>
          </template>
        </el-table-column>
        <el-table-column property="publishDate" label="供货状态" width="150">
          <template slot-scope="scope">
            <p>{{ scope.row.supplyOk ? '可以供货' : '不可供货' }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'
import RecommendButton from '../../components/RecommendButton.vue'

export default {
  name: '',
  props: {
    book: Object,
    rows: Array,
  },
  data() {
    return {
      noImg: require('images/no-image.png'),
    }
  },
  computed: {
    getImage() {
      const id = this.book.coverruid
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http'
        ? id
        : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
    bookType() {
      const type = this.book.dmcode
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    },
    getSupplyOk() {
      if (this.book.main) {
        if (this.book.relatdbooks.supplyOk) {
          return '正常供货'
        } else {
          return '不可供货'
        }
      } else {
        if (this.book.supplyOk) {
          return '正常供货'
        } else {
          return '不可供货'
        }
      }
    },
    getSaleCount() {
      if (this.book.main) {
        if (this.book.relatdbooks.saleCount > 0) {
          return '有现货'
        } else {
          return '没有现货'
        }
      } else {
        if (this.book.saleCount > 0) {
          return '有现货'
        } else {
          return '没有现货'
        }
      }
    },
  },
  methods: {
    glIcon(prop, idx) {
      const val = this.book[prop]
      return val.length > 1 && idx < val.length - 1 ? ' ; ' : ''
    },
    getType(type) {
      const num = parseInt(type)
      switch (num) {
        case 1987051001:
          return '纸书'
        case 1987052001:
          return '电子书'
        case 1987053001:
          return '数据库电子书'
        case 1987054001:
          return '纸+电'
        default:
          return ''
      }
    },
    disableButton(item) {
      const state = item.reviewState
      switch (state) {
        case 'manual_reject':
          return true
        case 'top_reject':
          return true
        case '7':
          return true
        case '9':
          return true
        default:
          return false
      }
    },
    getSeriesType(type) {
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    },
    toDetail(ruid) {
      let query = { id: ruid }
      if (this.bookType == '纸+电') query.type = 1
      let url = this.$router.resolve({
        name: 'BookDetail',
        query,
      })
      window.open(url.href, '_blank')
      // this.$router.push({path:'/BookDetail',query:query})
    },
    buttonClick(btn, idx) {
      let button = btn
      this.$refs.detailButton[idx].$el.blur()
      button.index = this.book.index
      this.$emit('button-click', button)
    },
    showInfo(content) {
      if (!content && content !== 0) {
        return false
      } else {
        return true
      }
    },
    toPublisher(name, type) {
      if (type == 0) {
        this.$emit('defaultPublisher', name)
      }
      if (type == 1) {
        this.$emit('defaultSeries', name)
      }
      // const code = this.book.publishercustomercode
      // let url = this.$router.resolve({
      //   name: 'PublisherDetail',
      //   query: { customer: code }
      // });
      // window.open(url.href, '_blank')
    },
  },
  components: { RecommendButton },
}
</script>

<style lang="scss" scoped>
/deep/ .el-table__body {
  min-width: 100%;
}
/deep/ .el-table__header {
  min-width: 100%;
  .cell {
    font-weight: normal !important;
    font-size: 16px !important;
    color: #666 !important;
  }
}
.redColor {
  color: #f00;
}
.record {
  margin-top: 20px;
}
.isbn {
  i {
    color: #5689ca;
    cursor: pointer;
    text-decoration: underline;
    font-style: normal;
  }
}
.book-detail {
  display: flex;
  .left {
    // width: 140px;
    // min-width: 140px;
    .cover {
      position: relative;
      height: 200px;
      width: 200px;
      border-bottom: 1px solid #e1e1e1;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border: 1px solid #e1e1e1;
      img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        cursor: pointer;
      }
      .type {
        position: absolute;
        left: 0;
        bottom: 0px;
        padding: 5px 10px;
        color: #fff;
        background-color: $mainColor;
      }
    }
    .count {
      padding: 15px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e1e1e1;
      border-top: 0;
      > div {
        flex: 1;
        &:nth-of-type(1),
        &:nth-of-type(2) {
          border-right: 1px solid #dddddd;
        }
        .name {
          color: #999;
        }
        .value {
          font-size: 18px;
          color: #2f8bd6;
        }
      }
    }
  }
  .info {
    word-break: break-all;
    margin-left: 20px;
    > * {
      margin-bottom: 10px;
    }
    h4 {
      font-size: 18px;
      cursor: pointer;
      &:hover {
        color: $mainColor;
      }
    }
    p {
      color: #666;
      font-size: 18px;
    }
    .detail {
      .row {
        display: block;
        > div {
          display: inline-block;
          margin-right: 15px;
        }
      }
      div {
        line-height: 1.5rem;
        font-size: 16px;
        b {
          font-weight: normal;
          color: #333;
          &::after {
            content: '：';
          }
        }
        span {
          color: #666;
          label {
            color: #666;
          }
        }
        .link {
          color: $mainColor;
          text-decoration: underline;
          cursor: pointer;
        }
        .child-publisher {
          color: #666;
        }
      }
      .inline-row {
        display: inline-block;
        margin-right: 15px;
      }
    }
    .version {
      display: block;
    }
    .buttons {
      margin-top: 20px;
    }
  }
}
.tips {
  font-size: 14px;
  span {
    background: #f7f7f7;
    color: #2f8bd6;
    padding: 3px 10px;
    margin-right: 10px;
  }
}
.button-review {
  display: flex;
  .review-info {
    margin-left: 15px;
    display: flex;
    align-items: center;
    span {
      color: #f00;
      font-size: 13px;
    }
  }
}
</style>
