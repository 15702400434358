<template>
  <div class="subject-classify">
    <div class="tabs">
      <span
        v-for="(tab, idx) in group"
        :key="tab.name"
        @click="switchTab(idx, true)"
        :class="{ 'active': tabIndex === idx }"
      >{{ tab.name }}</span>
    </div>
    <div class="location">
      <span @click="clearSubject">全部分类</span>
      <span v-for="(item, index) in subjects" :key="item.ruid">
        <span class="arrow"> > </span>
        <span @click="subjectClick(item, index)">{{ item.name }}</span>
      </span>
    </div>
    <div class="tags" v-if="subject&&subjects.length<3">
      <span
        v-for="item in subject.items"
        :key="item.ruid"
        v-show="item.result != 0"
        @click="tagClick(item)"
      >{{ item.name }} ({{ item.result }})</span>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    group: Array,
    filter: Array,
    isSubject: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      filters: [],
      subject: null,
      subjects: [],
      checked: false,
    }
  },
  watch: {
    group(val) {
      this.subject = val[this.tabIndex]
      this.checkFilter(this.filter)
    },
    isSubject(val) {
      if (!val) {
        this.tabIndex = 1
      }
    }
  },
  methods: {
    checkFilter(val) {
      if (!val || !val.length || this.checked) return false
      const sub = val[0]
      if (sub.type === 1) {
        this.switchTab(sub.type, false)
      }
      const tags = this.subject.items
      const target = tags.find(item => sub.word[1] == item.ruid)
      this.tagClick(target, false)
      this.checked = true
    },
    switchTab(idx, emit) {
      this.tabIndex = idx
      this.subject = this.group[idx]
      this.subjects = []
      if (emit) {
        this.$emit('subject-item', {})
      }
    },
    tagClick(item, emit=true) {
      this.subjects.push(item)
      if (emit) this.$emit('subject-item', item)
      else {
        this.$emit('filter-item', item)
      }
    },
    subjectClick(item, index) {
      let subjects = this.subjects.slice(0, index+1)
      this.subjects = subjects
      this.$emit('subject-item', item)
    },
    clearSubject() {
      this.subjects = []
      this.$emit('subject-item', {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .subject-classify {
    background-color: #fff;
    padding: 20px;
    .tabs {
      display: flex;
      justify-content: center;
      span {
        font-size: 1.2rem;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        width: 148px;
        text-align: center;
        color: $mainColor;
        border: 1px solid $mainColor;
      }
      .active {
        color: #fff;
        background-color: $mainColor;
      }
    }
    .location {
      display: block;
      margin: 20px 5px;
      span {
        font-size: 1.2rem;
        font-weight: bold;
        color: $mainColor;
        cursor: pointer;
        &:first-child {
          color: #000;
          &::before {
            content: '';
          }
        }
        .arrow {
          color: #000;
          cursor: default;
        }
      }
    }
    .tags {
      span {
        display: inline-block;
        margin: 5px;
        padding: 8px 15px;
        cursor: pointer;
        color: $mainColor;
        font-weight: bold;
        background-color: #EAF3FB;
        &:hover {
          color: #fff;
          background-color: $mainColor;
        }
      }
    }
  }
</style>
